@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PP Pangram Sans";
  src: url("../public/fonts/PPPangramSans-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PP Pangram Sans";
  src: url("../public/fonts/PPPangramSans-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PP Pangram Sans";
  src: url("../public/fonts/PPPangramSans-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "PP Pangram Sans";
  src: url("../public/fonts/PPPangramSans-Bold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "PP Pangram Sans";
  src: url("../public/fonts/PPPangramSans-Semibold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "PP Pangram Sans";
  src: url("../public/fonts/PPPangramSans-Extrabold.otf");
  font-weight: 800;
  font-style: normal;
}

ul li::marker {
  font-size: 1.5em;
  /* color: #00FF91; */
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 0;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);
  transition: opacity 250ms ease-out, transform 300ms ease;
}
.loader {
  border: 5px solid #313131; /* Light grey */
  border-top: 5px solid #00F0FF; /* Blue */
  border-radius: 50%;
  width: 38px;
  height: 38px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.CircularProgressbar-text{
  font-weight: 700;
}